w.aboust = {
    oConf: {},

    init: function () {
        this.initMap();
        this.gallery();
        this.lightGallery();
    },

    spinner: function () {
        $('.woocommerce-cart .qty').spinner();
    }

}

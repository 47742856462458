w.home = {
    oConf: {},

    init: function () {
        this.hHeader();
        this.hSlider();
        this.hVideo();
    },

    hHeader: function () {
        var hHeader = $('.hHeader__slider').lightSlider({
            item: 1,
            slideMargin: 0,
            controls: false,
            loop: true,
            enableDrag: false,
            auto: true,
            pause: 4000
        });

        $('.hHeader__btns .btn-left').click(function () {
            hHeader.goToPrevSlide();
        });

        $('.hHeader__btns .btn-right').click(function () {
            hHeader.goToNextSlide();
        });
    },

    hSlider: function () {
        var hSlider = $('.hSlider').lightSlider({
            item: 1,
            slideMargin: 0,
            controls: false,
            loop: true,
            enableDrag: false
        });

        $('.hSlider__btns .btn-left').click(function () {
            hSlider.goToPrevSlide();
        });

        $('.hSlider__btns .btn-right').click(function () {
            hSlider.goToNextSlide();
        });
    },
    
    hVideo: function () {
        $("[data-media]").on("click", function(e) {
            e.preventDefault();
            var $this = $(this);
            var videoUrl = $this.attr("data-media");
            var popup = $('.youtube-popup');
            var $popupIframe = $(popup).find("iframe");

            $popupIframe.attr("src", videoUrl);

            $this.closest("body").addClass("show-youtube-popup");
        });

        $(".youtube-popup").on("click", function(e) {
            e.preventDefault();
            e.stopPropagation();

            $("body").removeClass("show-youtube-popup");
            $('#youtube-home').removeAttr('src');
        });

        $(document).keydown(function(e){
            if(e.keyCode == 27) {
                if ($('body').hasClass('show-youtube-popup')) {
                    $('body').removeClass('show-youtube-popup');
                    $('#youtube-home').removeAttr('src');
                }
            }
        });

        $(".youtube-popup > iframe").on("click", function(e) {
            e.stopPropagation();
        });
    }
}

w.about = {
    oConf: {},

    init: function () {
        this.initMap();
        this.gallery();
        this.lightGallery();
    },

    gallery: function () {
        $("#gallery").justifiedGallery({
            'rowHeight': 160,
            'lastRow': 'justify',
            'captions': false
        });
    },

    lightGallery: function () {
        $("#gallery").lightGallery();
    },

    initMap: function () {
        var myLatLng = {lat: 37.0391300, lng: 22.1126500}

        var styleMyMap = new google.maps.StyledMapType(
            [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.terrain",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "saturation": "13"
                        },
                        {
                            "visibility": "off"
                        },
                        {
                            "hue": "#ff0000"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#34a2d6"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]

        )

        var map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: 37.0391300, lng: 22.1126500},
            zoom: 8,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain',
                    'styled_map']
            }
        });

        map.mapTypes.set('styled_map', styleMyMap);
        map.setMapTypeId('styled_map');



        // Create marker and set its position.
        var icon = {
            url: "https://letecheur.me/pointer-daphne.png",
            scaledSize: new google.maps.Size(70, 93), // scaled size

        }
        var marker = new google.maps.Marker({
            map: map,
            position: myLatLng,
            title: 'Daphne Oil',
            icon: icon
        });
        marker.set("id", 'mapmarker');

        google.maps.event.addDomListener(window, "resize", function () {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });


    }

}

w.culture = {
    oConf: {},

    init: function () {
        this.cSliders();
        this.lightGallery();
    },

    lightGallery: function () {
        $(".culture__gallery").lightGallery();
    },

    cSlider: function () {



        var hHeader = $('.culture__gallery').each(function (index) {
            $(this).lightSlider({
                item: 1,
                slideMargin: 0,
                controls: false,
                loop: true,
                enableDrag: false,
                pager: false
            });

            $('.hHeader__btns .btn-left').click(function () {
                hHeader.goToPrevSlide();
            });

            $('.hHeader__btns .btn-right').click(function () {
                hHeader.goToNextSlide();
            });
        });

    },

    cSliders: function () {

        var areaCarousels = [];
        w.$body.find(".culture__gallery").each(function(index){
            areaCarousels[index] = $(this).lightSlider({
                item: 1,
                slideMargin: 0,
                controls: false,
                loop: true,
                enableDrag: false,
                pager: false
            });
        });

        // A bit complex because multiple sliders on
        //  the page. We need an external array to save
        // lightSlider object (that exposes the API)
        $.each(areaCarousels, function(index){
            var lS_Object = areaCarousels[index];
            var $that = $(lS_Object);
            // .carousel-block is a wrapper outside of .area-carousel
            var $sliderControls = $that.closest('.culture__galleryWrapper').find('.culture__gallery__btns');

            $sliderControls.find('.btn-right').on('click',function() {
                lS_Object.goToNextSlide()
            });
            $sliderControls.find('.btn-left').on('click',function() {
                lS_Object.goToPrevSlide()
            });
        });

    }

}

w.product = {
    oConf: {},

    init: function () {
        this.sliderProduct();
        this.openToggle();
    },

    sliderProduct: function () {

        $('#sProduct__slider').lightSlider({
            item: 1,
            slideMargin: 0,
            controls: false,
            gallery: true,
            loop: true,
            thumbItem:5,
            onSliderLoad: function(el) {
                el.lightGallery({
                    selector: '#sProduct__slider .lslide',
                    zoom: true
                });
                el.find('li').zoom({
                    magnify: 1.5
                });
            }
        });

    },

    openToggle: function () {
        $('.sProduct__dropContent').hide();

        $('.sProduct__dropBtn').click(function () {
            $(this).toggleClass('open');
           $(this).next('.sProduct__dropContent').toggle('blind');
        });
    }



}

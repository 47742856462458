var w = {
    // global vars
    sCurrent: null,
    isLoaded: false,

    // global elements
    $body: null,

    // Navigation responsive

    // pages
    home: {},

    // setup
    init: function () {
        w.getElements();
        w.sCurrent = w.getCurrentPage();
        w.loadPage();
        w.isLoaded = true;
        w.menu();
        w.heightAutoDesktop();
        w.fixedMenu();
        w.menuResponsive();
    },
    loadPage: function () {
        if (w.sCurrent) w[w.sCurrent].init();
    },

    // functions
    getElements: function () {
        w.$body = $('body');
    },

    getCurrentPage: function () {
        if (w.$body.hasClass('home')) return 'home';
        if (w.$body.hasClass('page-template-page-about')) return 'about';
        if (w.$body.hasClass('page-template-page-culture')) return 'culture';
        if (w.$body.hasClass('page-template-page-contact')) return 'contact';
        if (w.$body.hasClass('single-product')) return 'product';

        return false;
    },

    menu: function () {
        $(".nav-links").accessibleMegaMenu({
            uuidPrefix: "accessible-megamenu",
            menuClass: "nav-menu",
            topNavItemClass: "nav-item",
            panelClass: "sub-nav",
            panelGroupClass: "sub-nav-group",
            hoverClass: "hover",
            focusClass: "focus",
            openClass: "open"
        });
        setTimeout(function () {
            $('body').removeClass('init');
        }, 500);
    },

    heightAutoDesktop: function () {
        $(".nav-item").mouseenter(function () {
            var ul = $(this).find('ul.sub-nav-group').height();
            $(this).find('div.sub-nav').css('height', ul);
        }).mouseleave(function () {
            $(this).find('div.sub-nav').css('height', '0');
        });

    },

    fixedMenu: function () {
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            //>=, not <=
            if (scroll >= 100) {
                //clearHeader, not clearheader - caps H
                $('body').addClass('fixedMenu');
            } else {
                $('body').removeClass('fixedMenu');
            }
        });
    },

    menuResponsive: function () {
        var hamburger = $(".hamburger");
        // On click
        hamburger.click(function () {
           $(this).toggleClass('is-active');
           $('body').toggleClass('is-responsive');
        });

        $('.layer').click(function () {
            hamburger.removeClass('is-active');
            $('body').removeClass('is-responsive');
        });
    }


};


$(window).on('load', w.init);
